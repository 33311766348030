import "./App.css";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Experts from "./pages/experts";
import Login from "./pages/login";
import Patients from "./pages/patients.js";
import Patient from "./pages/Patient.js";
import Dashboard from "./pages/Dashboard.js";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes,
} from "react-router-dom";
function App() {
  const token = useSelector((state) => state.token);
  const companyId = useSelector((state) => state.companyId);
  const companyName = useSelector((state) => state.companyName);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              <Navigate replace to="/dashboard" />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />

        <Route
          path="/experts"
          element={token ? <Experts /> : <Navigate replace to="/login" />}
        />
        <Route
          path="/expert/:id"
          element={token ? <Experts /> : <Navigate replace to="/login" />}
        />

        <Route
          path="/dashboard"
          element={
            token ? (
              <Dashboard companyId={companyId} />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />

        <Route
          path="/patients"
          element={
            token ? (
              <Patients companyId={companyId} />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />

        <Route
          path="/patient/:id"
          element={token ? <Patient /> : <Navigate replace to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
