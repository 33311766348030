import React from "react";

import { Link, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    // fontFamily: "Ubuntu",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    // fontFamily: "Lato",
  },
  link: {
    // fontFamily: "Lato",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
});

const PDFHeader = () => {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>Insights</Text>
        </View>
        <View style={styles.linkColumn}>
          <Text>
            <Link
              src="https://insights-dashboard.nichepharma.com/dashboard"
              style={styles.link}
            >
              Insights.com
            </Link>
          </Text>
        </View>
      </View>
    </>
  );
};

export default PDFHeader;
