import { combineReducers } from 'redux'
import {
  tokenReducer,
  companyIdReducer,
  companyNameReducer,
  userIdReducer,
} from './SigninReducer'

export const root = combineReducers({
  token: tokenReducer,
  companyId: companyIdReducer,
  companyName: companyNameReducer,
  userId: userIdReducer,
})
