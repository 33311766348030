import React, { useState, useEffect } from "react";
import Header from "../components/Header.js";
import Sidebar from "../components/Sidebar.js";
import { backendLink } from "../keys_dev";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { FormControl, Input, InputLabel, Button } from "@mui/material";
import Pagination from "rc-pagination";
import Spinner from "react-bootstrap/Spinner";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Audio } from "react-loader-spinner";

import Table from "react-bootstrap/Table";
import "../stylesheets/patients.css";

export default function Patients(props) {
  let index = 1;

  const navigate = useNavigate();
  const [waiting, setWaiting] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState();
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [numberOfVisits, setNumberOfVisits] = useState("");
  const [maxNumberOfVisits, setMaxNumberOfVisits] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [companyId, setCompanyId] = useState();

  let [currentPage, setCurrentPage] = useState(1);
  let [patientsCount, setPatientsCount] = useState(0);

  const getPatients = async (page) => {
    await axios({
      url: `${backendLink}/patients/getTotalNumberOfPatientsPerCompany/${props.companyId}?page=${page}`,
      method: "get",
      data: {
        page: "1",
        limit: "100",
        includeDeleted: true,
      },
    }).then((res) => {
      console.log(res);
      setPatientsCount(res.data.count);
      setPatients(res.data.data);
      setFilteredPatients(
        res.data.data.sort((a, b) => b.visits.length - a.visits.length)
      );
    });
  };
  const getMaxNumberOfVisits = async () => {
    let max = 0;
    for (let patient of patients) {
      if (patient.visits.length > max) {
        max = patient.visits.length;
      }
    }
    setMaxNumberOfVisits(max);
  };
  const handleSearchInput = (event) => {
    const newPatients = patients.filter(
      (x) =>
        x.name.includes(event.target.value) |
        x.phone_number.includes(event.target.value) |
        x.age.toString().includes(event.target.value) |
        x.gender.includes(event.target.value)
    );
    setFilteredPatients(newPatients);
  };
  const handleChange = (event) => {
    setNumberOfVisits(event.target.value);
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleFilter = () => {
    let newPatients = [];
    if (numberOfVisits == "") {
      newPatients = patients;
    } else {
      newPatients = patients.filter((x) => x.visits.length == numberOfVisits);
    }
    setFilteredPatients(newPatients);
  };
  const getPatient = async (patientId) => {
    let patientTemp = {};
    for (let patient of patients) {
      if (patient._id == patientId) {
        setPatient(patient);
        patientTemp = patient;
        break;
      }
    }
    navigate(`/patient/${patientId}`, { state: patientTemp });

    console.log(patient);
  };

  const excute = async () => {
    setWaiting(true);
    setCompanyId(props.companyId);
    await getPatients(1);
    setWaiting(false);
  };

  useEffect(() => {
    excute();
    // getMaxNumberOfVisits()
  }, []);

  return (
    <div>
      {!waiting && (
        <div>
          <Header companyId={props.companyId} />
          <Sidebar />
          <div className="patientsList">
            <div>
              <div className="filterHeader" onClick={handleOpen}>
                <span className="filterWord">Filter</span>
                <span className="filterIndicator">
                  {isOpen ? <BsChevronUp /> : <BsChevronDown />}
                </span>
              </div>
              {isOpen ? (
                <div className="collapse">
                  <div>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 150 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Number Of Visits
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={numberOfVisits}
                        onChange={handleChange}
                        label="Number Of Visits"
                      >
                        <MenuItem value={""}>All</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="filterButton">
                    <Button className="filterButtonWord" onClick={handleFilter}>
                      Filter
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="searchRow">
              <FormControl className="searchForm" onChange={handleSearchInput}>
                <InputLabel htmlFor="search" style={{ marginLeft: "-13px" }}>
                  Search
                </InputLabel>
                <Input id="search" aria-describedby="search" />
              </FormControl>
            </div>
            <div>
              <Table className="patientsListview">
                <thead>
                  <tr className="listviewHeaders text-center">
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Visits</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPatients.map((patient) => {
                    return (
                      <tr
                        className="listviewDataRow"
                        key={patient._id}
                        onClick={() => getPatient(patient._id)}
                      >
                        <td className="listviewDataCell">
                          {index++ + 100 * (currentPage - 1)}
                        </td>
                        <td className="listviewDataCell">
                          {/* {patient.name} */}
                        </td>
                        <td className="listviewDataCell">
                          {/* {patient.phone_number} */}
                        </td>
                        <td className="listviewDataCell">{patient.age}</td>
                        <td className="listviewDataCell">{patient.gender}</td>
                        <td className="listviewDataCell">
                          {patient.visits.length}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {patientsCount > 100 && (
                <div className="pagination">
                  {
                    <Pagination
                      current={currentPage}
                      defaultPageSize={100}
                      pageSize={100}
                      total={patientsCount}
                      onChange={async (current, pageSize) => {
                        const params = new Proxy(
                          new URLSearchParams(window.location.search),
                          {
                            get: (searchParams, prop) => searchParams.get(prop),
                          }
                        );
                        setCurrentPage(current);
                        setWaiting(true);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        console.log(current);
                        await getPatients(current);
                        setWaiting(false);
                      }}
                    />
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {waiting && (
        <div className="loading">
          {/* <Spinner animation="grow" variant="info" /> */}
          {/* <Spinner animation="grow" variant="primary" /> */}
          {/* <Spinner animation="grow" variant="dark" /> */}
          <Audio
            height="300"
            width="100"
            radius="10"
            color="#D3D641"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
    </div>
  );
}
