import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backendLink } from "../keys_dev";
import Header from "../components/Header.js";
import Sidebar from "../components/Sidebar.js";
import "../stylesheets/experts.css";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PDFFile from "../components/PDF-Generator";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  FormControl,
  Input,
  InputLabel,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function Experts() {
  const companyId = useSelector((state) => state.companyId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [experts, setExperts] = useState([]);
  const [filteredExperts, setFilteredExperts] = useState([]);
  const [chosenExpert, setChosenExpert] = useState();
  const [chosenExpertPatients, setChosenExpertPatients] = useState();
  const [chosenExpertPatientsAge, setChosenExpertPatientsAge] = useState();
  const [selectedPatients, setSelectedPatients] = useState(null);

  //DELETE EXPERT MODAL
  const [openDelete, setOpenDelete] = useState(false);
  const handleClickDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleDeleteExpert = () => {
    axios({
      url: `${backendLink}/users/${pathArray[2]}`,
      method: "delete",
      data: {
        // page: '1',
        // limit: '100',
        // includeDeleted: true,
      },
    }).then((res) => {
      navigate(`/experts`);
      handleCloseDelete();
    });
  };

  //CREATE EXPERT MODAL
  const [openCreate, setOpenCreate] = useState(false);
  const handleClickCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    setCreatExpertData({
      name: "",
      age: "",
      city: "",
      gender: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setCreateExpertErrorMessage("");
  };

  const [createExpertErrorMessage, setCreateExpertErrorMessage] = useState("");
  const [createExpertData, setCreatExpertData] = useState({
    name: "",
    age: "",
    country: "",
    province: "",
    city: "",
    gender: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleCreateExpertDataChange = (event) => {
    let newValue = event.target.value;
    let name = event.target.name;
    setCreatExpertData((createExpertData) => {
      return {
        ...createExpertData,
        [name]: newValue,
      };
    });
  };

  const handleCreateExpert = () => {
    if (
      createExpertData.name === "" ||
      createExpertData.age === "" ||
      createExpertData.country === "" ||
      createExpertData.province === "" ||
      createExpertData.city === "" ||
      createExpertData.gender === "" ||
      createExpertData.phoneNumber === "" ||
      createExpertData.email === "" ||
      createExpertData.password === "" ||
      createExpertData.confirmPassword === ""
    ) {
      setCreateExpertErrorMessage("Please fill all fields");
      return;
    }
    if (createExpertData.password !== createExpertData.confirmPassword) {
      setCreateExpertErrorMessage("Your passwords do not match");
      return;
    }
    let finalData = {
      name: createExpertData.name,
      email: createExpertData.email,
      phone_number: createExpertData.phoneNumber,
      age: parseInt(createExpertData.age),
      location: {
        country: createExpertData.country,
        province: createExpertData.province,
        city: createExpertData.city,
      },
      gender: createExpertData.gender,
      password: createExpertData.password,
      company_id: companyId,
    };
    axios({
      url: `${backendLink}/users`,
      method: "post",
      data: finalData,
    })
      .then((res) => {
        handleCloseCreate();
        window.location.reload();
      })
      .catch((error) => {
        setCreateExpertErrorMessage(error.response.data.error);
      });
  };

  var pathArray = window.location.pathname.split("/");
  useEffect(() => {
    if (pathArray[1] === "expert" && pathArray[2]) {
      setLoading(true);
      //GET EXPERT INFO
      axios({
        url: `${backendLink}/users/${pathArray[2]}`,
        method: "get",
        data: {
          // page: '1',
          // limit: '100',
          // includeDeleted: true,
        },
      }).then((res) => {
        console.log(res.data.user);
        setChosenExpert(res.data.user);
      });

      //GET EXPERT PATIENTS
      axios({
        url: `${backendLink}/visits/get-patients-data/${pathArray[2]}`,
        method: "get",
        data: {
          // page: '1',
          // limit: '100',
          // includeDeleted: true,
        },
      }).then((res) => {
        console.log(res.data);
        setLoading(false);
        setChosenExpertPatients(res.data.data);
        const average =
          res.data.data.reduce(
            (total, next) => total + parseInt(next.age, 10),
            0
          ) / res.data.data.length;
        setChosenExpertPatientsAge(average);
      });
    } else {
      axios({
        url: `${backendLink}/company/${companyId}/users`,
        method: "get",
        data: {
          // page: '1',
          // limit: '100',
          // includeDeleted: true,
        },
      }).then((res) => {
        console.log(res.data.data);
        setExperts(res.data.data);
        setFilteredExperts(res.data.data);
      });
    }
  }, [pathArray[2]]);
  function MapExperts() {
    let finalArray = filteredExperts.sort(
      (a, b) => b.patientIds.length - a.patientIds.length
    );
    let index = 1;
    return finalArray.map((expert) => (
      <Row
        key={expert._id}
        className="expertRow expertRowHover"
        onClick={() => handleExpertClick(expert._id)}
      >
        <Col>{index++}</Col>
        <Col>{expert.name}</Col>
        <Col>{expert.phone_number}</Col>
        <Col>{expert.age}</Col>
        <Col>{expert.gender}</Col>
        <Col>{expert.email}</Col>
        <Col>{expert.patientIds ? expert.patientIds.length : 0}</Col>
      </Row>
    ));
  }
  function MapExpertPatients() {
    return chosenExpertPatients.map((patient) => (
      <Row key={patient._id} className="expertRow expertRowHover">
        <Col onClick={() => handlePatientClick(patient._id)}>
          {patient.name}
        </Col>
        <Col onClick={() => handlePatientClick(patient._id)}>
          {patient.phone_number}
        </Col>
        <Col onClick={() => handlePatientClick(patient._id)}>{patient.age}</Col>
        <Col onClick={() => handlePatientClick(patient._id)}>
          {patient.gender}
        </Col>
        <Col onClick={() => handlePatientClick(patient._id)}>
          {patient.visits.length}
        </Col>
        <Col>
          <Button
            className={selectedPatients === patient ? "button-41" : "button-42"}
            onClick={() => {
              setSelectedPatients(patient);
            }}
          >
            {selectedPatients === patient ? "Selected" : "Select"}
          </Button>
        </Col>
      </Row>
    ));
  }
  const handleSearchInput = (event) => {
    console.log(experts);
    const newExperts = experts.filter(
      (x) =>
        x.name.includes(event.target.value) |
        x.phone_number.includes(event.target.value) |
        // x.age.toString().includes(event.target.value) |
        // x.gender.includes(event.target.value) |
        x.email.includes(event.target.value)
    );
    setFilteredExperts(newExperts);
  };
  const handleExpertClick = (expertId) => {
    navigate(`/expert/${expertId}`);
  };

  const handlePatientClick = (patientId) => {
    let patientTemp = {};
    for (let patient of chosenExpertPatients) {
      if (patient._id === patientId) {
        patientTemp = patient;
        break;
      }
    }
    navigate(`/patient/${patientId}`, { state: patientTemp });
  };

  console.log({ chosenExpertPatients });

  const exportToExcel = (patient) => {
    let visits = patient.visits;
    const columns = ["#", "Questions"];
    let report = [];
    visits.forEach((visit, index) => {
      columns.push(`Visit-${index + 1} Answers`);
      visit.expert_questionnaire.submissions.forEach((submission, i) => {
        if (index === 0) {
          report.push({
            "#": i,
            Questions: submission.question,
            [`Visit-${index + 1} Answers`]: submission.answer,
          });
        } else {
          report[i][`Visit-${index + 1} Answers`] = submission.answer;
        }
      });
    });

    const fileName = `${patient.name} Report`;

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(report, { header: columns });
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Generate XLSX file and save it
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const exportAllToExcel = (patientsData) => {
    const wb = XLSX.utils.book_new();
    const questions = {};
    let maxVisits = 0;

    patientsData.forEach((patient, patientIndex) => {
      // for patients that has more patients than previous ones
      maxVisits = Math.max(maxVisits, patient.visits.length);

      patient.visits.forEach((visit, visitIndex) => {
        visit.expert_questionnaire.submissions.forEach((submission) => {
          if (!questions[submission.question]) {
            questions[submission.question] = [];
          }
          while (questions[submission.question].length <= patientIndex) {
            questions[submission.question].push({
              name: patient.name,
              age: patient.age,
              gender: patient.gender,
              answers: Array(maxVisits).fill("N/A"),
            });
          }
          questions[submission.question][patientIndex].answers[visitIndex] =
            submission.answer;
        });
      });
    });

    Object.values(questions).forEach((patients) => {
      patients.forEach((patient) => {
        while (patient.answers.length < maxVisits) {
          patient.answers.push("N/A");
        }
      });
    });

    Object.keys(questions).forEach((question, index) => {
      // Header row for field names
      const headerRow = ["Name", "Age", "Gender"];
      for (let i = 0; i < maxVisits; i++) {
        headerRow.push(`Visit-${i + 1} Answers`);
      }

      // getting records for each field
      const patientData = questions[question].map((patient) => {
        return [patient.name, patient.age, patient.gender].concat(
          patient.answers
        );
      });

      const ws_data = [headerRow].concat(patientData);
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // regex for invalid sheet names
      const sheetName = question.substring(0, 31).replace(/[:\\/?*[\]]/g, "");
      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = ws;
    });

    // exporting
    const filename = chosenExpert.name + " Patients Report";
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  return (
    <div className="expertsWrapper">
      <Header />
      <Sidebar />
      {pathArray[1] === "experts" ? (
        <Container className="expertsContainer wrapperForSideBarMargins">
          <Row className="searchRow">
            <Col>
              <FormControl className="searchForm" onChange={handleSearchInput}>
                <InputLabel htmlFor="search">Search</InputLabel>
                <Input id="search" aria-describedby="search" />
              </FormControl>
            </Col>
            <Col>
              {/* <Tooltip title='Create Expert'>
                <PlusIcon
                  className='plusIcon'
                  onClick={handleClickCreate}
                ></PlusIcon>
              </Tooltip> */}
              <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openCreate}
                onClose={handleCloseCreate}
              >
                <DialogTitle>Create New Expert</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="age"
                    label="Age"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="country"
                    label="Country"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="province"
                    label="Province"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="city"
                    label="City"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    label="Gender"
                    select
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                    value={createExpertData.gender}
                    name="gender"
                  >
                    <MenuItem id="gender" value={"male"}>
                      {"male"}
                    </MenuItem>
                    <MenuItem id="gender" value={"female"}>
                      {"female"}
                    </MenuItem>
                  </TextField>
                  <TextField
                    margin="dense"
                    name="phoneNumber"
                    label="Phone number"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="email"
                    label="E-mail"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <TextField
                    margin="dense"
                    name="confirmPassword"
                    label="Confirm password"
                    type="password"
                    fullWidth
                    variant="standard"
                    onChange={handleCreateExpertDataChange}
                  />
                  <p className="errorMessage">{createExpertErrorMessage}</p>
                  <Button
                    className="createExpertButton"
                    variant="contained"
                    onClick={handleCreateExpert}
                  >
                    CREATE
                  </Button>
                </DialogContent>
              </Dialog>
            </Col>
          </Row>
          <Row>
            <Row className="expertRow expertsHeaderRow">
              <Col>#</Col>
              <Col>Name</Col>
              <Col>Phone Number</Col>
              <Col>Age</Col>
              <Col>Gender</Col>
              <Col>Email</Col>
              <Col>Number Of Patients</Col>
            </Row>
            <MapExperts></MapExperts>
          </Row>
        </Container>
      ) : (
        chosenExpert && (
          <div className="wrapperForSideBarMargins">
            <div className="expertName">{chosenExpert.name}</div>
            <Container className="expertsContainer expertFirstCard">
              <Row>
                <Col className="doctorInfoText">Doctor Info</Col>
                <Col>
                  <Button
                    className="deleteExpertButton"
                    variant="contained"
                    onClick={handleClickDelete}
                  >
                    Delete
                  </Button>
                  <Dialog
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Deleting " + chosenExpert.name}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {"Are you sure you want delete " + chosenExpert.name}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDelete}>Cancel</Button>
                      <Button onClick={handleDeleteExpert}>Delete</Button>
                    </DialogActions>
                  </Dialog>
                </Col>
              </Row>
              <Row>
                <Col>Age</Col>
                <Col>: {chosenExpert.age}</Col>
              </Row>
              <Row>
                <Col>Phone Number</Col>
                <Col>: {chosenExpert.phone_number}</Col>
              </Row>
              <Row>
                <Col>Gender</Col>
                <Col>: {chosenExpert.gender}</Col>
              </Row>
              <Row>
                <Col>Email</Col>
                <Col>: {chosenExpert.email}</Col>
              </Row>
            </Container>
            <Divider className="expertDivider" />
            {chosenExpertPatients && (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ marginRight: "16px" }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ width: "23px", height: "23px" }}
                      />
                    ) : (
                      <Typography className="accordionTitle">
                        Number of Patients : {chosenExpertPatients.length} ,
                        Mean age :{" "}
                        {chosenExpertPatientsAge
                          ? chosenExpertPatientsAge.toFixed(2)
                          : 0}
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container className="expertsContainer patientContainer">
                      <Row>
                        <Row className="expertRow expertsHeaderRow">
                          {/* <Col>Name</Col> */}
                          {/* <Col>Phone Number</Col> */}
                          <Col>Age</Col>
                          <Col>Gender</Col>
                          <Col>Number Of Visits</Col>
                          <Col>Patient report</Col>
                        </Row>
                        <MapExpertPatients></MapExpertPatients>
                      </Row>

                      {/* <div
                        style={{
                          textAlign: "center",
                          margin: "25px",
                        }}
                      >
                        {selectedPatients && (
                          <>
                            <PDFDownloadLink
                              fileName={selectedPatients.name + " Report.pdf"}
                              document={
                                <PDFFile
                                  patients={chosenExpertPatients}
                                  doctor={chosenExpert}
                                  patient={selectedPatients}
                                />
                              }
                            >
                              {({ loading }) =>
                                loading ? (
                                  <Button
                                    disabled={loading}
                                    className="button-43"
                                    role="button"
                                  >
                                    <span>Loading Patients Reports...</span>
                                  </Button>
                                ) : (
                                  <Button className="button-43" role="button">
                                    <span
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      Patients Reports PDF
                                    </span>
                                  </Button>
                                )
                              }
                            </PDFDownloadLink>
                            <button
                              style={{ margin: "15px 0" }}
                              className="button-43"
                              onClick={() => {
                                exportToExcel(selectedPatients);
                              }}
                            >
                              PATIENTS REPORTS XLSX
                            </button>
                          </>
                        )}
                      </div> */}
                    </Container>
                  </AccordionDetails>
                </Accordion>
                {/* <Container>
                  <button
                    style={{ margin: "15px 0" }}
                    className={`button-43 ${loading || chosenExpertPatients.length === 0 ? 'button-disabled' : ''}`}
                    disabled={loading || chosenExpertPatients.length === 0}
                    onClick={() => {
                      if (!loading && chosenExpertPatients.length > 0) {
                        exportAllToExcel(chosenExpertPatients);
                      }
                    }}
                  >
                    Collective Patients Report XLSX
                  </button>
                </Container> */}
              </>
            )}
          </div>
        )
      )}
    </div>
  );
}
