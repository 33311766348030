import React from "react";

import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  //================ Page Common Styles ===============//
  container: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },

  page: {
    backgroundColor: "#E4E4E4",
  },
  body: {
    paddingTop: 30,
    paddingBottom: 65,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 26,
    textAlign: "center",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    margin: "20px 0 0",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    // fontFamily: "Ubuntu",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  em: {
    fontStyle: "bold",
  },

  //================ Table Styles ===============//
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
  },
  tableRow0: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    // margin: 12,
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontStyle: "bold",
    margin: 0,
    padding: 0,
  },
  cell: {
    padding: "10px 15px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
    flexGrow: "1",
    margin: "0px",
  },
  patientsName: {
    width: "25%",
    fontFamily: "Times-Roman",
  },
  patientsPhone: {
    width: "25%",
  },
  patientsAge: {
    width: "15%",
  },
  patientsGender: {
    width: "15%",
  },
  patientsVisits: {
    width: "20%",
  },
  mainTitle: {
    // fontFamily: "Lato Bold",
    fontSize: 14,
    marginBottom: 10,
    textTransform: "uppercase",
  },
});

const PDFPatients = ({ patients }) => {
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.mainTitle}>Doctor Patients</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text
              style={[styles.tableHeader, styles.cell, styles.patientsName]}
            >
              Name
            </Text>
            <Text
              style={[styles.tableHeader, styles.cell, styles.patientsPhone]}
            >
              Phone Number
            </Text>
            <Text style={[styles.tableHeader, styles.cell, styles.patientsAge]}>
              Age
            </Text>
            <Text
              style={[styles.tableHeader, styles.cell, styles.patientsGender]}
            >
              Gender
            </Text>
            <Text
              style={[styles.tableHeader, styles.cell, styles.patientsVisits]}
            >
              Number Of Visits
            </Text>
          </View>
          {patients.map((patient, i) => {
            return (
              <View
                key={patient._id}
                style={i % 2 === 0 ? styles.tableRow0 : styles.tableRow}
              >
                <Text style={[styles.text, styles.cell, styles.patientsName]}>
                  {patient.name}
                </Text>
                <Text style={[styles.text, styles.cell, styles.patientsPhone]}>
                  {patient.phone_number}
                </Text>
                <Text style={[styles.text, styles.cell, styles.patientsAge]}>
                  {patient.age}
                </Text>
                <Text style={[styles.text, styles.cell, styles.patientsGender]}>
                  {patient.gender}
                </Text>
                <Text style={[styles.text, styles.cell, styles.patientsVisits]}>
                  {patient.visits.length}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </>
  );
};

export default PDFPatients;
