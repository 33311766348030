import React from "react";
import PDFHeader from "./PDFSections/PDFHeader";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";
import DoctorInfo from "./PDFSections/DoctorInfo";
import PDFPatients from "./PDFSections/PDFPatients";
import PatientInfo from "./PDFSections/PatientInfo";
import PatientVisits from "./PDFSections/PatientVisits";

// import fontSource from "./PDFSections/Fonts/Amiri-Regular.ttf";

// Font.register({
//   family: "Ubuntu",
//   // src: `./PDFSections/Fonts/Cairo-Regular.ttf`,
//   src: fontSource,
//   // src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
// });

const styles = StyleSheet.create({
  //================ Page Common Styles ===============//
  page: {
    backgroundColor: "#fff",
  },
  body: {
    paddingTop: 30,
    paddingBottom: 65,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 26,
    textAlign: "center",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    margin: "20px 0 0",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  em: {
    fontStyle: "bold",
  },

  //================ Table Styles ===============//
  table: {
    width: "100%",
    borderWidth: 2,
    display: "flex",
    flexDirection: "column",
    marginVertical: 12,
    padding: "0px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
  },
  tableHeader: {
    // margin: 12,
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontStyle: "bold",
  },
  cell: {
    padding: "20px",
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
    flexGrow: "1",
    margin: "0px",
  },
  patientsName: {
    width: "25%",
  },
  patientsPhone: {
    width: "25%",
  },
  patientsAge: {
    width: "15%",
  },
  patientsGender: {
    width: "15%",
  },
  patientsVisits: {
    width: "20%",
  },
});

const PDFFile = ({ patients, doctor, patient }) => {
  console.log({ doctor });
  console.log({ patients });

  return (
    <Document>
      <Page style={[styles.body, styles.page]}>
        <PDFHeader />
        <DoctorInfo doctor={doctor} />
        <PDFPatients patients={patients} />
        <PatientInfo patient={patient} />
        <PatientVisits patient={patient} />
      </Page>
    </Document>
  );
};

export default PDFFile;
