export const tokenReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return action.payload
    case 'UNSET_TOKEN':
      return action.payload
    default:
      return state
  }
}

export const companyIdReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_COMPANYID':
      return action.payload
    case 'UNSET_COMPANYID':
      return action.payload
    default:
      return state
  }
}
export const companyNameReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_COMPANYNAME':
      return action.payload
    case 'UNSET_COMPANYNAME':
      return action.payload
    default:
      return state
  }
}

export const userIdReducer = (state = '', action) => {
  switch (action.type) {
    case 'SET_USERID':
      return action.payload
    case 'UNSET_USERID':
      return action.payload
    default:
      return state
  }
}
