import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { backendLink } from "../keys_dev";
import "../stylesheets/login.css";
import axios from "axios";
import { FormControl, Input, InputLabel, Button, Card } from "@mui/material";

import { Audio } from "react-loader-spinner";
import { loginAPI } from "../globalState/actions/AuthActions";
import { useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { wait } from "@testing-library/user-event/dist/utils";

export default function Experts() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [waiting, setWaiting] = useState(false);

  const dispatch = useDispatch();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    setWaiting(true);
    const response = await dispatch(loginAPI(username, password, navigate));
    if (response == "ERROR") {
      setErrorMessage("Invalid username/password");
    }
    setWaiting(false);
  };
  return (
    <div className="">
      {!waiting && (
        <div className="loginWrapper">
          <h1>Insights App</h1>
          <Card className="loginCard">
            <p className="errorMessage">{errorMessage}</p>
            <FormControl className="loginForms" onChange={handleUsernameChange}>
              <InputLabel className="loginFormsInput" htmlFor="username">
                Username
              </InputLabel>
              <Input id="username" aria-describedby="username" />
            </FormControl>
            <FormControl className="loginForms" onChange={handlePasswordChange}>
              <InputLabel className="loginFormsInput" htmlFor="password">
                Password
              </InputLabel>
              <Input
                type="password"
                id="password"
                aria-describedby="password"
              />
            </FormControl>
            <Button
              className="loginButton"
              variant="contained"
              onClick={handleLogin}
            >
              Login
            </Button>
          </Card>
        </div>
      )}
      {waiting && (
        <div className="loading">
          {/* <Spinner animation="grow" variant="info" /> */}
          {/* <Spinner animation="grow" variant="primary" /> */}
          {/* <Spinner animation="grow" variant="dark" /> */}
          <Audio
            height="300"
            width="100"
            radius="10"
            color="#3F51B5"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
    </div>
  );
}
