import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import '../stylesheets/header.css'
import { backendLink } from '../keys_dev'
import axios from 'axios'
import { AiOutlineMenu } from 'react-icons/ai'

export default function Header(props) {
  const companyName = useSelector((state) => state.companyName)
  //const [companyName , setCompanyName] = useState()
  // const getCompanyName = async ()=>{
  //     await axios({
  //         url: `${backendLink}/company/${props.companyId}`,
  //         method: 'get',
  //         data: {
  //             page: '1',
  //             limit: '100',
  //             includeDeleted:true
  //         },
  //     })
  //     .then((res)=>{
  //         console.log(res)
  //         setCompanyName(res.data.data.name)
  //     })
  // }



  return (
    <div className='header'>
      <div className='secondDiv'>
        <span className='menuSpan'>
          <AiOutlineMenu className='menuButton' />
        </span>
        <span className='companyName'>{companyName}</span>
      </div>
    </div>
  )
}
