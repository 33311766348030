import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    // fontFamily: "Lato Italic",
  },
  detailContainer: {
    flexDirection: "row",
  },
  detailLeftColumn: {
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 10,
  },
  details: {
    fontSize: 10,
    // fontFamily: "Lato",
  },
  headerContainer: {
    flexDirection: "row",
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  rightColumn: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    justifySelf: "flex-end",
  },
  title: {
    fontSize: 11,
    color: "black",
    textDecoration: "none",
    // fontFamily: "Lato Bold",
  },
  mainTitle: {
    // fontFamily: "Lato Bold",
    fontSize: 14,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  item: {
    // fontFamily: "Times-Roman",
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    flex: 1,
    fontSize: 10,
    // fontFamily: "Lato",
  },
});

const PatientInfo = ({ patient }) => {
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.mainTitle}>Patient Information</Text>
        <View style={styles.entryContainer}>
          {patient.name && (
            <View style={styles.item}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.itemContent}>Name: {patient.name}</Text>
            </View>
          )}
          {patient.age && (
            <View style={styles.item}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.itemContent}>Age: {patient.age}</Text>
            </View>
          )}
          {patient.phone_number && (
            <View style={styles.item}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.itemContent}>
                Phone Number: {patient.phone_number}
              </Text>
            </View>
          )}
          {patient.gender && (
            <View style={styles.item}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.itemContent}>Gender: {patient.gender}</Text>
            </View>
          )}
          {patient.visits.length && (
            <View style={styles.item}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text style={styles.itemContent}>
                Number Of Visits : {patient.visits.length}
              </Text>
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default PatientInfo;
