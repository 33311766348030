import axios from 'axios'
import { backendLink } from '../../keys_dev'
//import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'

export const loginAPI = (username, password, navigate) => {
  return async (dispatch, getState) => {
    return await axios({
      url: `${backendLink}/company/companies/control-panel/login`,
      method: 'POST',
      data: {
        username,
        password,
      },
    }).then((res) => {
      if (res.data.status == 'success') {
        console.log(res.data)
        const token = res.data.response.token
        const company_id = res.data.response.companyId
        const company_name = res.data.response.companyName
        const user_id = res.data.response.user._id
        dispatch(setToken(token))
        dispatch(setCompanyId(company_id))
        dispatch(setCompanyName(company_name))
        dispatch(setUserId(user_id))
        navigate('/')
      } else {
        return 'ERROR'
        dispatch(unsetToken())
        dispatch(unsetCompanyId())
        dispatch(unsetCompanyName())
        dispatch(unsetUserId())
      }
      return res.data
    })
  }
}

export const checkTokenExpired = (navigate) => {
  return function (dispatch, getState) {
    const token = getState().token
    if (!token) {
      navigate('/')
      return
    }
    if (jwt_decode(token).exp < Date.now() / 1000) {
      dispatch(logout(navigate))
      return true
    } else {
      return false
    }
  }
}

const setToken = (payload) => {
  return {
    type: 'SET_TOKEN',
    payload,
  }
}

const unsetToken = () => {
  return {
    type: 'UNSET_TOKEN',
    payload: '',
  }
}

const setCompanyId = (payload) => {
  return {
    type: 'SET_COMPANYID',
    payload,
  }
}

const unsetCompanyId = () => {
  return {
    type: 'UNSET_COMPANYID',
    payload: '',
  }
}
const setCompanyName = (payload) => {
  return {
    type: 'SET_COMPANYNAME',
    payload,
  }
}

const unsetCompanyName = () => {
  return {
    type: 'UNSET_COMPANYNAME',
    payload: '',
  }
}

const setUserId = (payload) => {
  return {
    type: 'SET_USERID',
    payload,
  }
}

const unsetUserId = () => {
  return {
    type: 'UNSET_USERID',
    payload: '',
  }
}

export const logout = (navigate) => {
  return (dispatch, getState) => {
    dispatch(unsetToken())
    dispatch(unsetCompanyId())
    dispatch(unsetCompanyName())
    dispatch(unsetUserId())

    navigate('/')
  }
}
