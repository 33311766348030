import React, { useState, useEffect } from "react";
import Header from "../components/Header.js";
import Sidebar from "../components/Sidebar.js";
import { backendLink } from "../keys_dev";
import "../stylesheets/dashboard.css";
// import { useHistory, useLocation } from 'react-router'
// import { useSelector, useDispatch } from 'react-redux'
import axios from "axios";
import { FaBriefcaseMedical } from "react-icons/fa";
import { MdHealing } from "react-icons/md";
import { FaIdBadge } from "react-icons/fa";
import { MdEventSeat } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import Spinner from "react-bootstrap/Spinner";
import { Audio } from "react-loader-spinner";

Chart.register(...registerables);

export default function Dashboard(props) {
  const [numberOfMaleDoctors, setNumberOfMaleDoctors] = useState(0);
  const [numberOfFemaleDoctors, setNumberOfFemaleDoctors] = useState(0);
  const [numberOfMalePatients, setNumberOfMalePatients] = useState(0);
  const [numberOfFemalePatients, setNumberOfFemalePatients] = useState(0);
  const [averageMalePatientAge, setAverageMalePatientAge] = useState(0);
  const [averageFemalePatientAge, setAverageFemalePatientAge] = useState(0);
  const [totalAveragePatientAge, setTotalAveragePatientAge] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [numberOfVisitsPerCompany, setNumberOfVisitsPerCompany] = useState([]);
  const [patientsVisitsNumber, setPatientsVisitsNumber] = useState([]);
  const [waiting, setWaiting] = useState(true);

  //companyId is hardcoded dont forget to change it
  const getTotalNumberOfDoctors = async () => {
    await axios({
      url: `${backendLink}/users/getTotalDoctorsPerCompany/${props.companyId}`,
      method: "get",
      data: {
        page: "1",
        limit: "100",
        includeDeleted: true,
      },
    }).then((res) => {
      //   console.log(res)
      let maleNumber = 0;
      let femaleNumber = 0;
      res.data.data.forEach((doctor) => {
        //   console.log(doctor)
        if (doctor.gender == "male") {
          maleNumber += 1;
        } else {
          femaleNumber += 1;
        }
      });
      setNumberOfFemaleDoctors(femaleNumber);
      setNumberOfMaleDoctors(maleNumber);
      //   console.log(numberOfMaleDoctors,numberOfFemaleDoctors)
    });
  };
  //companyId is hardcoded dont forget to change it
  const getTotalNumberOfPatients = async () => {
    await axios({
      url: `${backendLink}/patients/getTotalOfPatientsPerCompany/${props.companyId}`,
      method: "get",
      data: {
        page: "1",
        limit: "100",
        includeDeleted: true,
      },
    }).then((res) => {
      // console.log(res)
      // let malePatient = 0;
      // let femalePatient = 0;
      // let maleAge = 0;
      // let femaleAge = 0;
      // let totalAge = 0;
      // res.data.data.forEach((patient) => {
      //   if (patient.gender == "male") {
      //     malePatient += 1;
      //     maleAge += patient.age;
      //   } else {
      //     femalePatient += 1;
      //     femaleAge += patient.age;
      //   }
      //   totalAge += patient.age;
      // });
      setNumberOfFemalePatients(res.data.data.femalePatients);
      setNumberOfMalePatients(res.data.data.malePatients);
      // setAverageFemalePatientAge(femaleAge / femalePatient);
      // setAverageMalePatientAge(maleAge / malePatient);
      // setTotalAveragePatientAge(totalAge / (malePatient + femalePatient));
      // calculateVisitsOfPatients(res.data.data);
      setPatientsVisitsNumber(res.data.data.visits);
    });
  };
  //companyId is hardcoded dont forget to change it
  const getNumberOfVisitsPerCompany = async () => {
    await axios({
      url: `${backendLink}/company/${props.companyId}`,
      method: "get",
      data: {
        page: "1",
        limit: "100",
        includeDeleted: true,
      },
    }).then((res) => {
      // console.log(res)
      setNumberOfVisitsPerCompany(res.data.data.numberOfVisits);
    });
  };

  const calculateVisitsOfPatients = (data) => {
    let temp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    // console.log(data)
    for (let cell of data) {
      temp[cell.visits.length - 1]++;
    }
    setPatientsVisitsNumber(temp);
  };
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const settingBackgroundColoAccordingToNumberOfVisits = () => {
    let temp = [];
    for (let i = 0; i < 4; i++) {
      temp.push(getRandomColor());
    }
    setBackgroundColor(temp);
  };

  const initialCalls = async () => {
    getTotalNumberOfDoctors();
    getTotalNumberOfPatients();
    getNumberOfVisitsPerCompany();
    settingBackgroundColoAccordingToNumberOfVisits();
  };

  const exceute = async () => {
    setWaiting(true);
    await initialCalls();
    setWaiting(false);
  };

  useEffect(() => {
    exceute();
  }, []);

  const state1 = {
    labels: ["Total", "Male", "Female"],
    datasets: [
      {
        backgroundColor: [getRandomColor(), getRandomColor(), getRandomColor()],
        data: [
          numberOfMaleDoctors + numberOfFemaleDoctors,
          numberOfMaleDoctors,
          numberOfFemaleDoctors,
          0,
        ],
      },
    ],
  };
  const state2 = {
    labels: ["Total", "Male", "Female"],
    datasets: [
      {
        backgroundColor: [getRandomColor(), getRandomColor(), getRandomColor()],
        data: [
          numberOfMalePatients + numberOfFemalePatients,
          numberOfMalePatients,
          numberOfFemalePatients,
          0,
        ],
      },
    ],
  };
  const state3 = {
    labels: ["Average Age", "Average Male", "Average Female"],
    datasets: [
      {
        backgroundColor: [getRandomColor(), getRandomColor(), getRandomColor()],
        data: [
          totalAveragePatientAge,
          averageMalePatientAge,
          averageFemalePatientAge,
          0,
        ],
      },
    ],
  };
  const state4 = {
    labels: numberOfVisitsPerCompany,
    datasets: [
      {
        backgroundColor: backgroundColor,
        data: patientsVisitsNumber,
      },
    ],
  };
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: "Total Number of Doctors",
        fontSize: 20,
      },
      legend: {
        display: false,
      },
    },
  };
  const options2 = {
    plugins: {
      title: {
        display: true,
        text: "Total Number of Patients",
        fontSize: 20,
      },
      legend: {
        display: false,
      },
    },
  };
  const options3 = {
    plugins: {
      title: {
        display: true,
        text: "Average Patient Age ",
        fontSize: 20,
      },
      legend: {
        display: false,
      },
    },
  };
  const options4 = {
    plugins: {
      title: {
        display: true,
        text: "Patients Visits Completed",
        fontSize: 20,
      },
      legend: {
        display: false,
      },
    },
  };
  return (
    <div>
      {!waiting && (
        <div>
          <Header companyId={props.companyId} />
          <Sidebar />
          {/* <div className="barChartDivLeft">
                <FaBriefcaseMedical className="icon"/>
                <Bar data={state1} options={options1}/>
            </div> */}
          <div className="barChartDivRight2 extraBarChartDiv">
            <MdHealing className="icon" />
            <Bar data={state2} options={options2} />
          </div>
          {/* <div className="barChartDivLeft extraBarChartDiv">
                <FaIdBadge className="icon"/>
                <Bar data={state3} options={options3}/>
            </div> */}
          <div className="barChartDivRight extraBarChartDiv">
            <MdEventSeat className="icon" />
            <Bar data={state4} options={options4} />
          </div>
        </div>
      )}

      {waiting && (
        <div className="loading">
          {/* <Spinner animation="grow" variant="info" /> */}
          {/* <Spinner animation="grow" variant="primary" /> */}
          {/* <Spinner animation="grow" variant="dark" /> */}
          <Audio
            height="300"
            width="100"
            radius="10"
            color="#3F51B5"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
    </div>
  );
}
