import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import '../stylesheets/sidebar.css'
import { AiFillHome } from 'react-icons/ai'
import { MdOutlineWheelchairPickup } from 'react-icons/md'
import { MdGroups } from 'react-icons/md'
import { MdLock } from 'react-icons/md'

import { logout } from '../globalState/actions/AuthActions'
export default function Sidebar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleClick = (moduleName) => {
    navigate(`/${moduleName}`)
  }
  const handleLogout = async () => {
    await dispatch(logout(navigate))
  }
  return (
    <div className='sidebar'>
      <div className='section' onClick={() => handleClick('dashboard')}>
        <span>
          <AiFillHome className='iconSidebar' />
        </span>
        <span className='moduleName'>Dashboard</span>
      </div>
      <div className='section' onClick={() => handleClick('patients')}>
        <span>
          <MdOutlineWheelchairPickup className='iconSidebar' />
        </span>
        <span className='moduleName'>Patients</span>
      </div>
      <div className='section' onClick={() => handleClick('experts')}>
        <span>
          <MdGroups className='iconSidebar' />
        </span>
        <span className='moduleName'>Experts</span>
      </div>
      <div className='section' onClick={() => handleLogout()}>
        <span>
          <MdLock className='iconSidebar' />
        </span>
        <span className='moduleName'>Logout</span>
      </div>
    </div>
  )
}
