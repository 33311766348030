import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header.js";
import Sidebar from "../components/Sidebar.js";
import { backendLink } from "../keys_dev";
import "../stylesheets/patient.css";
import { BsChevronDown } from "react-icons/bs";
import { BsChevronUp } from "react-icons/bs";
import axios from "axios";
import { Button } from "@mui/material";
import Table from "react-bootstrap/esm/Table";

export default function Patient() {
  const location = useLocation();
  const navigate = useNavigate();

  const [numberOfVisits, setNumberOfVisits] = useState();
  const [open, setOpen] = useState([]);

  const handleOpenVisitPanel = (index) => {
    let array = [];
    for (let i = 0; i < open.length; i++) {
      if (i == index) {
        array.push(!open[i]);
      } else {
        array.push(open[i]);
      }
    }
    setOpen(array);
  };
  const handleDelete = async () => {
    await axios({
      url: `${backendLink}/patients/${location.state._id}`,
      method: "delete",
      data: {
        page: "1",
        limit: "100",
        includeDeleted: true,
      },
    }).then((res) => {
      console.log(res);
      navigate("/patients");
    });
  };

  const initilizeBooleanArray = () => {
    let array = [];
    for (let i = 0; i < location.state.visits.length; i++) {
      array.push(false);
    }
    setOpen(array);
  };

  useEffect(() => {
    setNumberOfVisits(location.state.visits.length);
    initilizeBooleanArray();
  }, []);

  return (
    <div>
      <Header companyId={location.state.company_id} />
      <Sidebar />
      <div className="patientBody">
        <div>
          <h1 className="patientName">{/* {location.state.name} */}</h1>
        </div>
        <div className="patientInfoCard">
          <span className="patientInfo">Patient Info</span>
          <Button className="deleteButton" onClick={handleDelete}>
            Delete
          </Button>
          <div className="infoDiv">
            <p className="infoTitle">
              <strong>Age :</strong>
            </p>
            <p className="infoData">{location.state.age}</p>
          </div>
          <div className="infoDiv">
            <p className="infoTitle">
              <strong>Phone Number :</strong>
            </p>
            <p className="infoData">{/* {location.state.phone_number} */}</p>
          </div>
          <div className="infoDiv">
            <p className="infoTitle">
              <strong>Gender :</strong>
            </p>
            <p className="infoData">{location.state.gender}</p>
          </div>
          <div className="infoDiv">
            <p className="infoTitle">
              <strong>Number Of Visits :</strong>
            </p>
            <p className="infoData">{location.state.visits.length}</p>
          </div>
        </div>
        <hr className="horizontalLine"></hr>
        {[...Array(numberOfVisits)].map((e, i) => (
          <div className="visitDiv" key={i}>
            <div
              className="visitMidDiv"
              onClick={() => handleOpenVisitPanel(i)}
            >
              <span className="visitPanel">Visit Number : {i + 1}</span>
              <span>{open[i] ? <BsChevronUp /> : <BsChevronDown />}</span>
            </div>
            {open[i] ? (
              <div>
                <Table className="patientTable">
                  <thead>
                    <tr className="headers">
                      <th className="questionNumber">#</th>
                      <th className="questions">Questions</th>
                      <th className="answers">Answers</th>
                    </tr>
                  </thead>
                  <tbody className="body">
                    {location.state.visits[
                      i
                    ].expert_questionnaire.submissions.map((submission) => {
                      return submission.answer !== "" ? (
                        <tr key={submission._id} className="dataRow">
                          <td className="questionNumberData">
                            {submission.questionNumber}
                          </td>
                          <td className="questionData">
                            {submission.question}
                          </td>
                          <td className="answerData">{submission.answer}</td>
                        </tr>
                      ) : (
                        <></>
                      );
                    })}
                  </tbody>
                </Table>
                {location.state.visits[i].imagesLinks.map(
                  (imageLink, imageNumber) => {
                    let newImageLink =
                      "https://insights-api.nichepharma.com/uploads/" +
                      imageLink.split("uploads")[1];
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        key={imageNumber}
                      >
                        <p className="patientInfo">Image {imageNumber}</p>
                        <img
                          style={{
                            objectFit: "cover",
                            width: "80%",
                          }}
                          src={newImageLink}
                          alt={newImageLink}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
